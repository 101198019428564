export default () => {
  return ($scope, $meta) => {
    $scope.$on("$routeChangeSuccess", (_event, { locals }) => {
      let content;

      if (locals.videomail) {
        content = "none";
      } else {
        content = "index,follow";
      }

      $meta.attr("content", content);
    });
  };
};
