export default ($resource) => {
  // https://docs.angularjs.org/api/ngResource/service/$resource
  const resourceName = "site";

  return $resource(
    `/${resourceName}/:name`,
    {},
    {
      snapshots: { method: "get", url: `/${resourceName}/snapshot` },
      clean: { method: "delete", url: `/${resourceName}/clean/:name/test/:test` },
    },
  );
};
