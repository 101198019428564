export default (clientSettings) => {
  return ($scope, $title) => {
    $scope.$on("$routeChangeSuccess", (_event, current) => {
      let title;

      if (current.locals.videomail) {
        title = current.locals.videomail.subject;
      } else {
        title = clientSettings.title;

        if (current.title) {
          title = `${current.title} — ${title}`;
        }
      }

      $title.html(title);
    });
  };
};
