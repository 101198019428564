import angular from "angular";

// leave it like that, "./client_settings.json"
// see https://github.com/dylang/npm-check/issues/132
import clientSettings from "./client_settings.json";
import E404Ctrl from "./controllers/404";
// import CleanCtrl from "./controllers/clean";
import HomeCtrl from "./controllers/home";
import ReportCtrl from "./controllers/report";
import ViewCtrl from "./controllers/view";
import WhitelistCtrl from "./controllers/whitelist";
import WhitelistThanksCtrl from "./controllers/whitelist-thanks";
import CompileDirective from "./directives/compile";
import ComposeTitleDirective from "./directives/composeTitle";
import EmailDirective from "./directives/email";
import HighlightLinkDirective from "./directives/highlightLink";
import MetaRobotsDirective from "./directives/metaRobots";
import OnClickPlayPauseDirective from "./directives/onClickPlayPause";
import TrustedFilter from "./filter/trusted";
import SiteFactory from "./resources/site";

angular
  .module("videomailApp", [
    "errorHandler",
    "ngRoute",
    "ngSanitize",
    "ngResource",
    "LocalStorageModule",
    "ng-email-list",
  ])
  .constant("clientSettings", clientSettings)

  .config(
    (
      $routeProvider,
      $locationProvider: angular.ILocationProvider,
      $logProvider: angular.ILogProvider,
      localStorageServiceProvider,
    ) => {
      $locationProvider.html5Mode({
        enabled: true,
      });

      $logProvider.debugEnabled(clientSettings["debug"]);
      localStorageServiceProvider.setPrefix(clientSettings["title"]);
      localStorageServiceProvider.setStorageCookieDomain(clientSettings["baseUrl"]);
      localStorageServiceProvider.setStorageCookie(0, "/");

      $routeProvider
        .when("/", {
          templateUrl: "home.html",
          controller: "HomeCtrl",
          showHeader: true,
          showSlogan: true,
        })

        .when("/reply/:key", {
          templateUrl: "home.html",
          controller: "HomeCtrl",
          showHeader: true,
          showSlogan: true,
        })

        .when("/replyAll/:key", {
          templateUrl: "home.html",
          controller: "HomeCtrl",
          showHeader: true,
          showSlogan: true,
          replyAll: true,
        })

        .when("/correct/:key", {
          templateUrl: "home.html",
          controller: "HomeCtrl",
          showHeader: true,
          showSlogan: true,
          correct: true,
        })

        .when("/versions", {
          templateUrl: "versions.html",
          title: "Changelog",
          showHeader: true,
          showSlogan: true,
        })

        .when("/videomail/:key/report", {
          templateUrl: "report.html",
          controller: "ReportCtrl",
          title: "Report",
          showHeader: true,
        })

        // .when("/clean", {
        //   templateUrl: "clean.html",
        //   controller: "CleanCtrl",
        //   showHeader: true,
        //   resolve: {
        //     sites(_$route, Site) {
        //       return Site.snapshots().$promise;
        //     },
        //   },
        // })

        .when("/whitelist", {
          templateUrl: "whitelist.html",
          controller: "WhitelistCtrl",
          showHeader: true,
          showSlogan: true,
        })

        .when("/whitelist/thanks", {
          templateUrl: "whitelist-thanks.html",
          controller: "WhitelistThanksCtrl",
          hideFooter: true,
        })

        .when("/videomail/:key", {
          templateUrl: "view.html",
          controller: "ViewCtrl",
          hideFooter: true,
          showMoreMetaTags: true,
        })

        .when("/faq", {
          templateUrl: "faq.html",
          title: "FAQ",
          showHeader: true,
        })

        .when("/errors/401", {
          templateUrl: "errors/401.html",
          title: "Authorization Required",
          showHeader: true,
        })

        .when("/errors/403", {
          templateUrl: "errors/403.html",
          title: "Forbidden",
          showHeader: true,
        })

        .when("/errors/410", {
          templateUrl: "errors/410.html",
          title: "Video has gone",
          showHeader: true,
        })

        .when("/errors/422", {
          templateUrl: "errors/422.html",
          title: "Unprocessable video",
          showHeader: true,
        })

        .when("/errors/500", {
          templateUrl: "errors/500.html",
          title: "Server error",
          showHeader: true,
        })

        .when("/errors/502", {
          templateUrl: "errors/502.html",
          title: "Bad gateway",
          showHeader: true,
        })

        .when("/errors/503", {
          templateUrl: "errors/503.html",
          title: "Unavailable",
          showHeader: true,
        })

        .when("/errors/504", {
          templateUrl: "errors/504.html",
          title: "Gateway timeout",
          showHeader: true,
        })

        .otherwise({
          templateUrl: "errors/404.html",
          controller: "E404Ctrl",
          title: "404",
          showHeader: true,
        });
    },
  )

  // Anything inside run(...) will be run on initialization
  .run([
    "$rootScope",
    "$anchorScroll",
    "$timeout",

    ($rootScope, $anchorScroll, $timeout: angular.ITimeoutService) => {
      $rootScope.$on("$locationChangeSuccess", () => {
        $rootScope.showHeader =
          $rootScope.showSlogan =
          $rootScope.showFooter =
          $rootScope.showMoreMetaTags =
          $rootScope.errorTemplate =
          $rootScope.videomail =
          $rootScope.error =
            null;
      });

      $rootScope.$on("$routeChangeError", (_event, _current, _previous, error: Error) => {
        $rootScope.showError(error);
      });

      $rootScope.$on("$viewContentLoaded", () => {
        $anchorScroll();
      });

      $rootScope.$on("$routeChangeSuccess", (_event, current) => {
        $anchorScroll();
        $rootScope.showHeader = current.showHeader;
        $rootScope.showSlogan = current.showSlogan;
        $rootScope.showFooter = !current.hideFooter;
        // make it available globally, because of meta tags
        $rootScope.showMoreMetaTags = current.showMoreMetaTags;
        $rootScope.videomail = current.locals.videomail;
      });

      $rootScope.showError = (err: Error) => {
        console.error(err.message.toString());
        void $timeout(() => {
          const status = err["status"] ? err["status"] : 500;
          $rootScope.error =
            err["response"] && err["response"].body ? err["response"].body : err;
          $rootScope.showSlogan = false;
          $rootScope.showHeader = true;
          $rootScope.errorTemplate = `errors/${status}.html`;
          $rootScope.showFooter = true;
        });
      };
    },
  ])

  .directive("highlightLink", ["$location", "$route", HighlightLinkDirective])
  // @ts-expect-error Fix later
  .directive("compile", ["$compile", "$templateCache", CompileDirective])
  .directive("composeTitle", ["clientSettings", ComposeTitleDirective])
  .directive("metaRobots", MetaRobotsDirective)
  .directive("email", EmailDirective)
  .directive("onClickPlayPause", OnClickPlayPauseDirective)

  .filter("trusted", ["$sce", TrustedFilter])

  .factory("Site", SiteFactory)

  .controller("HomeCtrl", [
    "$scope",
    "$rootScope",
    "$route",
    "$routeParams",
    "localStorageService",
    "$location",
    "$window",
    "$timeout",
    HomeCtrl,
  ])

  .controller("ReportCtrl", [
    "$scope",
    "$routeParams",
    "$timeout",
    "$rootScope",
    ReportCtrl,
  ])

  .controller("ViewCtrl", [
    "$scope",
    "$routeParams",
    "$timeout",
    "$window",
    "$rootScope",
    "$location",
    "$anchorScroll",
    ViewCtrl,
  ])

  // TODO Broken, fix later
  // .controller("CleanCtrl", [
  //   "$scope",
  //   "sites",
  //   "Site",
  //   "$routeParams",
  //   "$timeout",
  //   CleanCtrl,
  // ])

  .controller("WhitelistCtrl", ["$scope", "$location", "$http", "$log", WhitelistCtrl])

  .controller("WhitelistThanksCtrl", ["$scope", WhitelistThanksCtrl])

  .controller("E404Ctrl", ["$scope", "$location", "$timeout", E404Ctrl]);
