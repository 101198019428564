import munge from "munge";

export default () => {
  return (_$scope, $email, attrs) => {
    if (attrs && attrs.email) {
      const munged = munge(attrs.email);

      $email.html(munged);
      $email.attr("href", `mailto:${attrs.email}`);
    }
  };
};
