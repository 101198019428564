export default ($compile, $templateCache) => {
  return ($scope, $element, { compile }) => {
    $scope.$watch(
      ($scope) => {
        return $scope.$eval(compile);
      },
      (value) => {
        $element.empty();
        $element.append($compile($templateCache.get(value))($scope));
      },
    );
  };
};
