import angular from "angular";

export default ($location, $route) => {
  return ($scope, $element) => {
    const links = $element.find("a");

    if (!links) return;

    const l = links.length;
    const onClass = "current";
    let $link;
    let i;

    // nullify external links
    for (i = l - 1; i > 0; i--) {
      links[i].target && links.splice(i, 1);
    }

    for (i = 0; i < l; i++) {
      $link = angular.element(links[i]);

      $link.on("click", function () {
        // @ts-expect-error Fix later
        const path = this.pathname;

        // @ts-expect-error Fix later
        if (this.classList && this.classList.contains(onClass)) {
          $route.reload();
        } else {
          $location.path(path);
        }
      });
    }

    $scope.$on("$routeChangeSuccess", () => {
      let currentUrl = $location.path();
      const hash = $location.hash();

      if (hash) {
        currentUrl += `#${hash}`;
      }

      for (i = 0; i < l; i++) {
        $link = angular.element(links[i]);

        if ($link.attr("href") === currentUrl) {
          $link.addClass(onClass);
        } else {
          $link.removeClass(onClass);
        }
      }
    });
  };
};
