// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import clientSettings from "./client_settings.json";

const audioOffText = "Audio off";

const options = {
  verbose: clientSettings.debug,
  enableAutoPause: clientSettings.PRODUCTION,
  PRODUCTION: clientSettings.PRODUCTION,

  baseUrl: clientSettings.baseUrl,
  socketUrl: clientSettings.socketUrl,
  siteName: "videomail.io",

  audio: {
    enabled: clientSettings.audio.enabled,
    switch: clientSettings.audio.switch,
  },

  video: {
    fps: clientSettings.video.fps,
    limitSeconds: clientSettings.video.limitSeconds,
    countdown: clientSettings.video.countdown,
    width: clientSettings.video.width,
    height: clientSettings.video.height,
    facingModeButton: clientSettings.video.facingModeButton,
  },

  image: {
    quality: clientSettings.image.quality,
  },

  selectors: {
    formId: "email",
    keyInputName: "key",
    parentKeyInputName: "parentKey",
    aliasInputName: "alias",
  },

  notifier: {
    entertain: true,
    entertainClass: "b",
    entertainLimit: 12,
    entertainInterval: 6200,
  },

  text: {
    audioOff: audioOffText,
    buttons: {
      preview: "Stop",
    },
  },

  reportErrors: clientSettings.reportErrors,

  // fakeUaString: 'Mozilla/4.0 (compatible; MSIE 8.0; Windows NT 6.0; Trident/4.0)',

  adjustFormOnBrowserError: true,

  loadUserMediaOnRecord: clientSettings.loadUserMediaOnRecord,
};

// like that we can enable verbosity on production site whenever needed

function getParameterByName(name: string) {
  const url = global.window.location.href;
  name = name.replace(/[[\]]/g, "\\$&");
  // eslint-disable-next-line security/detect-non-literal-regexp
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const customDebug = getParameterByName("debug");

if (customDebug === "1" || customDebug === "true") {
  options.verbose = true;
} else if (customDebug === "0" || customDebug === "false") {
  options.verbose = false;
}

export default options;
