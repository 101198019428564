import ErrorStackParser from "error-stack-parser";
import stringify from "json-stringify-safe";

import clientSettings from "./client_settings.json";

global.window["sendLogLines"] = (logLines) => {
  const xhr = new global.window.XMLHttpRequest();
  xhr.open("POST", "/log-lines/");
  xhr.setRequestHeader("Content-type", "application/json");
  xhr.send(stringify(logLines));
};

global.window["reportVideomailError"] = (errData) => {
  try {
    errData.client = errData.client || {
      userAgent: global.navigator.userAgent,
    };

    errData.url = errData.url || global.window.location.href;
    errData.cookies = global.document.cookie;

    const xhr = new global.window.XMLHttpRequest();
    xhr.open("POST", "/client-error/");
    xhr.setRequestHeader("content-type", "application/json");
    xhr.send(stringify(errData));
  } catch (exc) {
    console.error("Failed to report error due to", exc);
  } finally {
    // make sure the developer sees it
    if (!clientSettings["PRODUCTION"]) {
      process.nextTick(() => {
        global.window.alert(errData.message);
      });
    }
  }
};

global.window.onerror = (message, source, line, column, err) => {
  let errData;

  try {
    let stringifiedMessage;

    try {
      stringifiedMessage = stringify(message, null, "\n");
    } catch (exc) {
      console.error("Failed to stringify message due to", exc);
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      stringifiedMessage = `(hard to stringify): ${message.toString()}`;
    }

    errData = {
      message: `onerror: ${stringifiedMessage}`,
      source,
      line,
      column,
    };

    if (!err) {
      err = new Error("fake-error-to-get-stack-trace");
    }

    try {
      errData.trace = ErrorStackParser.parse(err);
    } catch (exc) {
      errData.trace = "(failed to parse error stack)";
      console.error("Failed to parse an error due to", exc);
    }

    if (message["stack"]) {
      errData.stack = message["stack"];
    } else if (err.stack) {
      errData.stack = err.stack;
    }

    // https://github.com/binarykitchen/videomail-client/issues/83
    errData.explanation = message["explanation"];
    errData.client = message["client"];
    errData.logLines = message["logLines"];
    errData.url = message["url"];
    errData.code = message["code"];
    errData.type = message["type"];
    errData.name = message["name"];
  } catch (exc) {
    let originalMessage;

    if (errData.message) {
      originalMessage = errData.message;
    } else {
      originalMessage = stringify(message);
    }

    errData = {
      message: `error within onerror: ${stringify(exc, null, "\n")}`,
      explanation: `Original message was: ${originalMessage}, message function parameter was: ${stringify(message)}`,
    };
  }

  global.window["reportVideomailError"](errData);
};

// https://googlechrome.github.io/samples/promise-rejection-events/
// https://developer.mozilla.org/en-US/docs/Web/API/PromiseRejectionEvent
global.window.addEventListener("unhandledrejection", (event) => {
  const message = "Unhandled rejection";

  let explanation;

  if (event["explanation"]) {
    explanation = `Explanation:\n${event["explanation"]}`;
  } else {
    explanation = `Whole event:\n${stringify(event)}`;
  }

  if (event["message"]) {
    explanation += `,\nmessage:\n${stringify(event["message"])}`;
  }

  if (event.reason) {
    if (event.reason instanceof Error) {
      // can't stringify exceptions
      explanation += `,\r\nreason:\n\r${event.reason.toString()}`;
    } else {
      explanation += `,\r\nreason:\n\r${stringify(event.reason)}`;
    }
  }

  if (event["path"]) {
    // do nothing cos path can be an instance of Window causing
    // https://github.com/binarykitchen/videomail.io/issues/487
    // when trying to stringify it.
    // explanation += ',\npath:\n' + stringify(event.path)
  }

  if (event["stack"]) {
    explanation += `,\nstack:\n${stringify(event["stack"])}`;
  }

  if (event["detail"]) {
    explanation += `,\ndetail:\n${stringify(event["detail"])}`;
  }

  if (event["details"]) {
    explanation += `,\ndetails:\n${stringify(event["details"])}`;
  }

  global.window["reportVideomailError"]({
    message,
    explanation,
    logLines: event["logLines"],
    stack: event["stack"],
  });
});
