import angular from "angular";
import doWhilst from "async/doWhilst";
import _ from "lodash";
import VideomailClient from "videomail-client";

import options from "../options";

function replay(
  $scope,
  $timeout: angular.ITimeoutService,
  _$rootScope,
  videomailAlias,
  cb,
) {
  // instantiate client for each videomail separately
  const videomailClient = new VideomailClient(
    _.merge(
      {
        playerOnly: true,
        selectors: {
          containerId: videomailAlias,
        },
      },
      options,
    ),
  );

  videomailClient.get(videomailAlias, (err, videomail) => {
    if (err) {
      cb(err);
    } else {
      if (!videomailClient.canRecord()) {
        $scope.badBrowser = true;
      }

      $scope.$apply(() => {
        $scope.videomails.push(videomail);

        // Free stuff when controller has been destroyed (i.E. another link has been clicked)
        $scope.$on("$destroy", () => {
          videomailClient.unload();
        });

        void $timeout(() => {
          try {
            // Serializes input into a JSON-formatted string, this
            // to remove some angular-$$-crap
            const videomailString = angular.toJson(videomail);
            const videomailJson = JSON.parse(videomailString);

            videomailClient.replay(videomailJson);

            cb(null, videomail);
          } catch (exc) {
            cb(exc);
          }
        });
      });
    }
  });
}

export default function ($scope, { key }, $timeout, $window, $rootScope) {
  // todo make this navigable. thats an ugly workaround you have atm
  // because it would reload when using #
  $scope.goToReply = (alias) => {
    const aliasElement = document.getElementById(alias);

    if (aliasElement) {
      $window.scrollTo(0, aliasElement.offsetTop);
    }
  };

  $scope.videomails = [];

  let nextAliasToLoad = key;

  const iteratee = (cb) => {
    replay($scope, $timeout, $rootScope, nextAliasToLoad, cb);
  };

  const test = (videomail, cb) => {
    if (videomail && videomail.parent && videomail.parent.alias) {
      nextAliasToLoad = videomail.parent.alias;
    } else {
      nextAliasToLoad = false;
    }

    cb(null, !!nextAliasToLoad);
  };

  doWhilst(iteratee, test, (err) => {
    if (err) {
      $rootScope.showError(err);
    }
  });
}
