import "core-js/stable";
import "./errors";
import "angular";
import "angular-resource";
import "angular-local-storage";
import "angular-route";
import "angular-sanitize";
import "ng-email-list";
import "./errorHandler";
import "./videomail";
