export default () => {
  return (_$scope, $video) => {
    $video.on("click", (evt) => {
      evt.preventDefault();

      const video = evt.target;

      try {
        if (video.paused) {
          video.play();
        } else {
          video.pause();
        }
      } catch (exc) {
        const err = exc as Error;
        const message = err.toString();

        if (message && /NS_ERROR_UNEXPECTED/.test(message)) {
          // race condition/instability in firefox are ok,
          // see https://github.com/binarykitchen/videomail.io/issues/83
          console.error("onClickPlayPause throws exc:", exc);
        } else {
          throw exc;
        }
      }
    });
  };
};
