import angular from "angular";
// https://github.com/stacktracejs/stacktrace.js
import ErrorStackParser from "error-stack-parser";

angular.module("errorHandler", []).config(function ($provide) {
  $provide.decorator(
    "$exceptionHandler",
    function ($delegate, $injector, $window, $document) {
      return function (exception, cause) {
        $delegate(exception, cause);

        const clientSettings = $injector.get("clientSettings");
        const $log = $injector.get("$log");
        const $http = $injector.get("$http");

        let trace;
        let message;

        try {
          trace = ErrorStackParser.parse(exception);
        } catch (exception2) {
          const err2 = exception2 as Error;
          trace = "Failed to parse exception due to: " + err2.toString();
        }

        // Can happen, see
        // https://github.com/binarykitchen/videomail.io/issues/94
        if (exception.toString) {
          message = "Extracted: " + exception.toString();
        } else {
          message = "Unable to extract string from exception";
        }

        if (cause) {
          message += " (Context: " + cause + ")";
        }

        $http
          .post("/client-error/", {
            message: "$exHandler: " + message,
            url: $window.location.href,
            userAgent: $window.navigator.userAgent,
            cookies: $document.cookie,
            trace: trace,
          })
          .then(
            function () {
              // success
            },
            function () {
              // failure
              $log.warn("Error logging failed");
            },
          );

        // make sure the developer sees it
        !clientSettings.PRODUCTION && $window.alert(message);
      };
    },
  );
});
