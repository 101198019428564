export default function ($scope, $location, $http, $log) {
  $scope.submitWhitelistRequest = () => {
    $scope.processing = true;

    $http({
      url: "/whitelist/",
      method: "POST",
      data: $scope.whitelist,
    }).then(
      () => {
        $location.path("/whitelist/thanks/");
      },
      (response) => {
        $scope.processing = false;
        $scope.error = `Failed to submit whitelist (Error ${response.status})!`;
        $log.error(response);
      },
    );
  };
}
