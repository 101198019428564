import VideomailClient from "videomail-client";

import options from "../options";

export default function ($scope, { key }, $timeout: angular.ITimeoutService, $rootScope) {
  const videomailClient = new VideomailClient(options);

  videomailClient.get(key, (err, videomail) => {
    if (err) {
      $rootScope.showError(err);
    } else {
      void $timeout(() => {
        $scope.videomail = videomail;
      });
    }
  });
}
