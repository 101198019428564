import BeforeUnload from "before-unload";
import _ from "lodash";
import VideomailClient from "videomail-client";

import options from "../options";

const LEAVE_PROMPT =
  "You haven't sent your recorded videomail yet.\nIt is really okay to leave?";

const DEFAULT_DOCK_2_TO_HINT = "Send to more than one person? Add a comma in-between.";
const CORRECT_DOCK_2_TO_HINT = "Correct email address(es) before resending";

const DEFAULT_DOCK_2_CC_HINT =
  "Send a copy to more than one person? Add a comma in-between.";
const CORRECT_DOCK_2_CC_HINT = "Correct email address(es) before resending";

const DEFAULT_DOCK_2_BCC_HINT =
  "Send a blind copy to more than one person? Add a comma in-between.";
const CORRECT_DOCK_2_BCC_HINT = "Correct email address(es) before resending";

const INVALID_FORM = "Please fill out the form";
const INVALID_RECORD = "Please record a video first";

export default function (
  $scope,
  $rootScope,
  { current },
  $routeParams,
  localStorageServiceProvider,
  $location: angular.ILocationService,
  $window: angular.IWindowService,
  $timeout: angular.ITimeoutService,
) {
  if ($routeParams.audio) {
    const defaultAudioEnabled =
      $routeParams.audio === 1 ||
      $routeParams.audio === "true" ||
      $routeParams.audio === true;
    if (!defaultAudioEnabled) {
      options.audio.enabled = defaultAudioEnabled;
    }
  }
  const videomailClient = new VideomailClient(options);
  const toEmailList = document.getElementById("toEmailList") as HTMLObjectElement; // lol, don't care
  const ccEmailList = document.getElementById("ccEmailList") as HTMLObjectElement; // lol, don't care
  const bccEmailList = document.getElementById("bccEmailList") as HTMLObjectElement; // lol, don't care
  let whyInvalid: string | null = INVALID_RECORD;

  function getFromCookie() {
    return localStorageServiceProvider.get("from");
  }
  function getToCookie() {
    return localStorageServiceProvider.get("to");
  }
  function isCorrecting() {
    return current.$$route.correct;
  }

  function updateSubmitButtonTitle() {
    let submitButtonTitle;
    if (isCorrecting()) {
      submitButtonTitle = "Resend videomail";
    } else {
      submitButtonTitle = "Send videomail";
    }
    void $timeout(() => {
      $scope.submitButtonTitle = submitButtonTitle;
    });
  }
  function onEnablingAudio() {
    options.audio.enabled = true;
    updateSubmitButtonTitle();
  }
  function onDisablingAudio() {
    options.audio.enabled = false;
    updateSubmitButtonTitle();
  }
  function initModel(videomail) {
    void $timeout(() => {
      let videomailData: any = {};
      let dock2ToHint = DEFAULT_DOCK_2_TO_HINT;
      let dock2CcHint = DEFAULT_DOCK_2_CC_HINT;
      let dock2BccHint = DEFAULT_DOCK_2_BCC_HINT;
      let toEmailListRows = 2;
      let ccEmailListRows = 1;
      let bccEmailListRows = 1;
      const fromCookie = getFromCookie();
      const toCookie = getToCookie();
      const correcting = isCorrecting();
      if (videomail) {
        if (correcting) {
          videomailData = videomail;
          const to = videomailData.to || [];
          const sentTo = videomailData.sentTo || [];
          const rejectedTo = videomailData.rejectedTo || [];
          const cc = videomailData.cc || [];
          const sentCc = videomailData.sentCc || [];
          const rejectedCc = videomailData.rejectedCc || [];
          const bcc = videomailData.bcc || [];
          const sentBcc = videomailData.sentBcc || [];
          const rejectedBcc = videomailData.rejectedBcc || [];
          videomailData.to = _.union(to, sentTo, rejectedTo);
          videomailData.cc = _.union(cc, sentCc, rejectedCc);
          videomailData.bcc = _.union(bcc, sentBcc, rejectedBcc);
          $scope.showDock2To = rejectedTo.length > 0;
          $scope.showDock2Cc = rejectedCc.length > 0;
          $scope.showDock2Bcc = rejectedBcc.length > 0;
          dock2ToHint = CORRECT_DOCK_2_TO_HINT;
          dock2CcHint = CORRECT_DOCK_2_CC_HINT;
          dock2BccHint = CORRECT_DOCK_2_BCC_HINT;
        } else if (videomail.reply) {
          const replyAll = current.$$route.replyAll;
          videomailData = videomail.reply;
          if (replyAll && videomailData.sentToAll) {
            videomailData.to = videomailData.sentToAll;
          }
          if (!videomailData.from && fromCookie) {
            videomailData.from = fromCookie;
            if (videomailData.to && videomailData.to.length > 1) {
              videomailData.to = _.pull(videomailData.to, videomailData.from);
            }
          }
          // todo: break lines after each email with
          // https://github.com/editseras/ng-email-list/issues/11#issuecomment-131732224
        }
        if (videomailData.to) {
          toEmailListRows = videomailData.to.length + 1;
        }
        if (videomailData.cc) {
          ccEmailListRows = videomailData.cc.length + 1;
        }
        if (videomailData.bcc) {
          bccEmailListRows = videomailData.bcc.length + 1;
        }
      } else {
        videomailData.subject = $routeParams.subject;
        if ($routeParams.from) {
          videomailData.from = $routeParams.from;
        } else if (fromCookie) {
          videomailData.from = fromCookie;
        }
        if ($routeParams.to) {
          videomailData.to = [$routeParams.to];
        } else if (toCookie) {
          videomailData.to = toCookie;
        }
      }
      updateSubmitButtonTitle();
      $scope.toEmailListRows = toEmailListRows;
      $scope.ccEmailListRows = ccEmailListRows;
      $scope.bccEmailListRows = bccEmailListRows;
      $scope.videomail = videomailData;
      $scope.dock2ToHint = dock2ToHint;
      $scope.dock2CcHint = dock2CcHint;
      $scope.dock2BccHint = dock2BccHint;
      $scope.correcting = !!correcting;
      $scope.showCcEmailList = false;
      $scope.showBccEmailList = false;
      $scope.addCcEmailList = () => {
        $scope.showCcEmailList = true;
      };
      $scope.addBccEmailList = () => {
        $scope.showBccEmailList = true;
      };
    });
  }
  function onCountdown() {
    void $timeout(() => {
      $scope.showDock2Record = false;
    });
  }
  function onInvalid(why) {
    void $timeout(() => {
      if (why && why === "Video is not recorded") {
        whyInvalid = INVALID_RECORD;
        $scope.showDock2Record = true;
      } else if (why && why === "Please enter at least one recipient.") {
        whyInvalid = "Please enter one recipient";
      } else {
        whyInvalid = INVALID_FORM;
      }
      $scope.invalid = true;
    });
  }
  function onValid() {
    void $timeout(() => {
      whyInvalid = null;
      $scope.showDock2Record = $scope.invalid = false;
    });
  }
  function onSubmitted(_videomail, { nextUrl }) {
    // remember from address for next time
    if ($scope.videomail && $scope.videomail.from) {
      localStorageServiceProvider.set("from", $scope.videomail.from);
    }
    // remember to address for next time
    if ($scope.videomail && $scope.videomail.to) {
      localStorageServiceProvider.set("to", $scope.videomail.to);
    }
    void $timeout(() => {
      $location.path(nextUrl);
    });
  }
  function initSendWrapper() {
    const sendWrapper = document.getElementById("sendWrapper");
    if (sendWrapper) {
      sendWrapper.addEventListener("mouseenter", () => {
        void $timeout(() => {
          if (whyInvalid) {
            $scope.dock2Submit = whyInvalid;
            $scope.showDock2Submit = true;
          }
        });
      });
      sendWrapper.addEventListener("mouseleave", () => {
        void $timeout(() => {
          $scope.showDock2Submit = false;
        });
      });
    }
  }
  function initToRejects() {
    $scope.$watch("toRejects", (badEmails) => {
      void $timeout(() => {
        if ($scope.videomail) {
          // remove empty strings
          badEmails = _.without(badEmails, "", undefined);
          if (badEmails && badEmails.length > 0) {
            const errorMessage = `These email addresses are wrong:<br/>${badEmails.join(", ")}`;
            $scope.dock2ToHint = errorMessage;
            $scope.showDock2To = true;
            toEmailList.setCustomValidity(errorMessage);
          } else {
            if (isCorrecting()) {
              $scope.dock2ToHint = CORRECT_DOCK_2_TO_HINT;
            } else {
              $scope.dock2ToHint = DEFAULT_DOCK_2_TO_HINT;
            }
            toEmailList.setCustomValidity("");
          }
        }
      });
    });
  }
  function initCcRejects() {
    $scope.$watch("ccRejects", (badEmails) => {
      void $timeout(() => {
        if ($scope.videomail) {
          // remove empty strings
          badEmails = _.without(badEmails, "", undefined);
          if (badEmails && badEmails.length > 0) {
            const errorMessage = `These email addresses are wrong:<br/>${badEmails.join(", ")}`;
            $scope.dock2CcHint = errorMessage;
            $scope.showDock2Cc = true;
            ccEmailList.setCustomValidity(errorMessage);
          } else {
            if (isCorrecting()) {
              $scope.dock2CcHint = CORRECT_DOCK_2_CC_HINT;
            } else {
              $scope.dock2CcHint = DEFAULT_DOCK_2_CC_HINT;
            }
            ccEmailList.setCustomValidity("");
          }
        }
      });
    });
  }
  function initBccRejects() {
    $scope.$watch("bccRejects", (badEmails) => {
      void $timeout(() => {
        if ($scope.videomail) {
          // remove empty strings
          badEmails = _.without(badEmails, "", undefined);
          if (badEmails && badEmails.length > 0) {
            const errorMessage = `These email addresses are wrong:<br/>${badEmails.join(", ")}`;
            $scope.dock2BccHint = errorMessage;
            $scope.showDock2Bcc = true;
            bccEmailList.setCustomValidity(errorMessage);
          } else {
            if (isCorrecting()) {
              $scope.dock2BccHint = CORRECT_DOCK_2_BCC_HINT;
            } else {
              $scope.dock2BccHint = DEFAULT_DOCK_2_BCC_HINT;
            }
            bccEmailList.setCustomValidity("");
          }
        }
      });
    });
  }
  function initAllOnReady(videomail) {
    initToRejects();
    initCcRejects();
    initBccRejects();
    initSendWrapper();
    initModel(videomail);
  }
  function initForm(videomail?) {
    videomailClient.on(videomailClient.events.INVALID, onInvalid);
    videomailClient.on(videomailClient.events.VALID, onValid);
    videomailClient.on(videomailClient.events.SUBMITTED, onSubmitted);
    videomailClient.on(videomailClient.events.ENABLING_AUDIO, onEnablingAudio);
    videomailClient.on(videomailClient.events.DISABLING_AUDIO, onDisablingAudio);
    if (!isCorrecting()) {
      videomailClient.on(videomailClient.events.COUNTDOWN, onCountdown);
      videomailClient.on(videomailClient.events.FORM_READY, () => {
        initAllOnReady(videomail);
      });
      videomailClient.show();
    } else {
      videomailClient.on(videomailClient.events.REPLAY_SHOWN, () => {
        initAllOnReady(videomail);
      });
      videomailClient.replay(videomail);
    }
    function preventNavigating() {
      return videomailClient.isDirty() || videomailClient.isRecording();
    }
    let beforeUnload;
    if (!isCorrecting()) {
      beforeUnload = new BeforeUnload(LEAVE_PROMPT, preventNavigating);
      // Prevent changing link when a recorded videomail hasn't been sent
      // and the user confirms not to leave yet.
      $scope.$on("$routeChangeStart", (e) => {
        if (
          !e.defaultPrevented &&
          preventNavigating() &&
          !$window.confirm(LEAVE_PROMPT)
        ) {
          e.preventDefault();
        }
      });
    }
    // Free stuff when controller has been destroyed (i.e. another link has been clicked)
    $scope.$on("$destroy", () => {
      beforeUnload && beforeUnload.unregister();
      videomailClient.unload();
    });
  }
  function init() {
    if ($routeParams.key) {
      videomailClient.get($routeParams.key, (err, videomail) => {
        if (err) {
          $rootScope.showError(err);
        } else {
          initForm(videomail);
        }
      });
    } else {
      initForm();
    }
  }
  void $timeout(() => {
    $scope.showDock2Record = $scope.showDock2Submit = false;
    $scope.showTech = options.verbose;
    if (options.verbose) {
      $scope.sendLogLines = () => {
        $window.sendLogLines(videomailClient.getLogLines());
      };
    }
    init();
  });
}
