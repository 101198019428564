export default function (
  $scope,
  $location: angular.ILocationService,
  $timeout: angular.ITimeoutService,
) {
  void $timeout(() => {
    $scope.error = {
      url: $location.path(),
    };
  });
}
