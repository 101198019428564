module.exports={
  "image": {
    "quality": 0.38
  },
  "video": {
    "fps": 18,
    "limitSeconds": 180,
    "width": 400,
    "height": 300,
    "countdown": 3,
    "facingModeButton": true
  },
  "audio": {
    "enabled": false,
    "switch": true
  },
  "reportErrors": true,
  "loadUserMediaOnRecord": false,
  "title": "videomail.io",
  "LOCAL": false,
  "PRODUCTION": true,
  "minify": {
    "js": true
  },
  "baseUrl": "https://videomail.io",
  "socketUrl": "wss://videomail.io",
  "debug": false
}
